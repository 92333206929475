import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { find } from "lodash";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import { INTERNAL_COUNTRY_CODES } from "../../../constants/address";
import {
  ReferenceActions,
  ReferenceSelectors,
} from "../../../redux/references";
import { isPostcodeRequired } from "../../../models/postcode";

export const withAddressEdit = compose(
  withAppLoader({
    loadFn: ({ dispatch }) => dispatch(ReferenceActions.fetchCountries()),
  }),
  connect(state => ({ countries: ReferenceSelectors.getCountries(state) })),
  withProps(({ additionalInitialValues, parcel, countries }) => {
    const countryCode = parcel.deliveryDetails.address.countryCode;
    const isCountryCodeUK = countryCode === INTERNAL_COUNTRY_CODES.UK;
    const country = find(countries, { internalCode: countryCode });

    return {
      displayPostcodeFinder: isCountryCodeUK,
      isPostcodeRequired: isPostcodeRequired(country),
    };
  })
);
