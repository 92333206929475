import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import api from "./api";

export const findParcels = (query, options) =>
  api.get({
    path: "/parcels/find",
    query,
    options,
  });

export const searchParcels = (query, options) =>
  api.get({
    path: "/reference/parcels",
    query,
    options,
  });

export const searchCollections = (query, options) =>
  api.get({
    path: "/reference/collections",
    query,
    options,
  });

export const getParcel = (parcelCode, options) =>
  api.get({
    path: `/parcels/${parcelCode}`,
    options,
  });

export const getParcelActions = parcelCode =>
  api.get({
    path: `/actions/getActions/${parcelCode}`,
  });

export const getRelatedParcels = (parcelCode, query, options) =>
  api.get({
    path: `/parcels/${parcelCode}/findrelated`,
    query,
    options,
  });

export const addParcelToWatchList = parcelCode =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.WTC}`,
  });

export const removeParcelFromWatchList = parcelCode =>
  api.delete({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.WTR}`,
  });

export const changeDeliveryDate = (
  parcelCode,
  { deliveryDate, instructions, contactName, email, mobile }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.RED}`,
    body: {
      deliveryDate,
      instructions,
      notificationDetails: {
        contactName,
        email,
        mobile,
      },
    },
  });

export const changeDeliveryDateREI = (
  parcelCode,
  { deliveryDate, instructions, contactName, email, mobile }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.REI}/changeDate`,
    body: {
      deliveryDate,
      instructions,
      notificationDetails: {
        contactName,
        email,
        mobile,
      },
    },
  });

export const changeSafePlace = (
  parcelCode,
  { mobile, email, contactName, deliveryDate, safePlaceCode, instructions }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.SFP}`,
    body: {
      deliveryDate,
      safePlaceCode,
      instructions,
      notificationDetails: {
        mobile,
        email,
        contactName,
      },
    },
  });

export const deliverToNeighbour = (parcelCode, body) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.DTN}`,
    body,
  });

export const updateContactDetails = (
  parcelCode,
  { mobile, email, contactName }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.KMI}`,
    body: {
      notificationDetails: {
        mobile,
        email,
        contactName,
      },
    },
  });

export const provideDeliveryInstructions = (
  parcelCode,
  { mobile, email, contactName, instructions }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.LOC}`,
    body: {
      instructions,
      notificationDetails: {
        mobile,
        email,
        contactName,
      },
    },
  });

export const updateAddress = (parcelCode, { actionCode, ...rest }) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${actionCode}/alternativeAddress`,
    body: { ...rest },
  });

export const updateAddressAndService = (parcelCode, query) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.ALU}`,
    body: { ...query },
  });

export const callMe = (parcelCode, query) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.CME}`,
    body: { ...query },
  });

export const onCollectFromPickupShop = (parcelCode, body) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.PKU}`,
    body,
  });

export const disposeRequest = parcelCode =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.DIP}`,
  });

export const collectFromDepot = (
  parcelCode,
  { diaryDate, diaryTime, contactName, email, mobile }
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.TBC}`,
    body: {
      diaryDate,
      diaryTime,
      notificationDetails: {
        contactName,
        email,
        mobile,
      },
    },
  });

export const getDeliveryActionDates = ({ parcelCode, actionCode }, options) =>
  api.get({
    path: `/parcels/${parcelCode}/actions/${actionCode}`,
    options,
  });

export const getAlternativeDeliveryDates = (
  parcelCode,
  actionCode,
  query,
  options
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${actionCode}/alternativeDeliveryDates`,
    body: { ...query },
    options,
  });

export const getAlternativeDeliveryTimes = (
  parcelCode,
  actionCode,
  query,
  options
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${actionCode}/alternativeDeliveryTimes`,
    body: { ...query },
    options,
  });

export const getReferences = (query, options) =>
  api
    .get({
      path: "/reference/",
      query,
      options,
    })
    .then(({ data: { countries, products, services, depots } }) => ({
      data: {
        countries,
        products,
        services,
        depots,
      },
    }));

export const getRanges = (accountCode, options) =>
  api
    .get({
      path: `/reference/${accountCode}`,
      options,
    })
    .then(({ data }) => ({
      data,
      accountCode,
    }));

export const filterParcels = (query, options) =>
  api.get({
    path: "/parcels/filter",
    query,
    options,
  });

export const getDashboardStats = (query, options) =>
  api.get({
    path: "/parcels/dashboard/stats",
    query,
    options,
  });

export const getDashboardPDFavailable = options =>
  api.get({
    path: "/parcels/dashboard/pdf/available",
    options,
  });

export const getServiceDisruption = (query, options) =>
  api.get({
    path: "/reference/matrix-issue-summary",
    query,
    options,
  });

export const getCountries = (query, options) =>
  api.get({
    path: `/reference/countries`,
    query,
    options,
  });

export const getParcelsFindByCodeByType = (type, query, options) =>
  api.get({
    path: `/parcels/dashboard/${type}`,
    query,
    options,
  });

export const getParcelsPage = (query, options) =>
  api
    .get({
      path: "/parcels/page",
      query,
      options,
    })
    .then(({ data: { findByTotal, parcels } }) => ({
      data: {
        findByCode: query.findByCode,
        findByTotal,
        parcels: query.startPos > findByTotal ? [] : parcels,
      },
    }));
export const getDeliveryExceptions = options =>
  api.get({
    path: "/parcels/dashboard/exceptions/delivery-exceptions",
    query: {},
    options,
  });

export const getCsvLink = (findByCode, options) =>
  api.get({
    path: "/parcels/csvLink",
    query: {
      findByCode,
    },
    options,
  });

export const getReturnAddress = (parcelCode, options) =>
  api.get({
    path: `/parcels/${parcelCode}/returnaddress`,
    options,
  });

export const getUpgradeDeliveryTimes = (
  { parcelCode, actionCode },
  query,
  options
) =>
  api.get({
    path: `/parcels/${parcelCode}/actions/${actionCode}/upgradeDeliveryTimes`,
    query,
    options,
  });

export const getPickupLocationShop = (parcelCode, query, options) =>
  api.get({
    path: `/parcels/${parcelCode}/getPickupLocation`,
    query: {
      searchCriteria: "orderBy=nearest&country=GB",
    },
    options,
  });

export const getWatchList = (query, options) =>
  api.get({
    path: "/parcels/watch-list",
    query,
    options,
  });

export const returnToConsignor = (
  parcelCode,
  {
    parcelCodes,
    notificationDetails,
    address,
    instructions,
    sendersReference,
    isInternationalParcel,
    actionCode,
  },
  options
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${actionCode}/returnToConsignor`,
    body: {
      notificationDetails,
      address,
      instructions,
      sendersReference,
      isInternationalParcel,
      parcelCodes,
    },
    options,
  });

export const adjustService = (
  parcelCode,
  {
    sendersReference,
    instructions,
    deliveryDate,
    parcelCodes,
    notificationDetails,
    serviceCode,
    freeOfCharge,
  },
  options
) =>
  api.post({
    path: `/parcels/${parcelCode}/actions/${PARCEL_ACTION_CODE.UPC}`,
    body: {
      sendersReference,
      instructions,
      deliveryDate,
      parcelCodes,
      notificationDetails,
      serviceCode,
      freeOfCharge,
    },
    options,
  });
