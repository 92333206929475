import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { deliveriesApi } from "../../apis";

export const ActionTypes = createActionTypes("ENUM", {
  FETCH: createAsyncActionTypes("FETCH"),
  FETCH_RANGES: createAsyncActionTypes("FETCH_RANGES"),
  FETCH_EXCEPTION: createAsyncActionTypes("FETCH_EXCEPTION"),
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
});

export const fetchReferences = createAsyncAction(
  (query = {}, fetchOptions) =>
    deliveriesApi.getReferences(query, fetchOptions),
  ActionTypes.FETCH
);

export const fetchRanges = createAsyncAction(
  (accountCode, fetchOptions) =>
    deliveriesApi.getRanges(accountCode, fetchOptions),
  ActionTypes.FETCH_RANGES
);

export const fetchException = createAsyncAction(
  (query = {}, fetchOptions) =>
    deliveriesApi.getDeliveryExceptions(query, fetchOptions).then(({ data }) =>
      data.map(val => ({
        label: `${val.stateDescription} ${val.reasonDescription}`,
        value: val.reasonCode,
      }))
    ),
  ActionTypes.FETCH_EXCEPTION
);

export const fetchCountries = createAsyncAction(
  async (query = {}, fetchOptions) =>
    deliveriesApi.getCountries(query, fetchOptions),
  ActionTypes.FETCH_COUNTRIES
);
