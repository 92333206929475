import { SAFE_PLACE_TYPE } from "@dpdgroupuk/redback-enums";

import {
  ACCEPT_CONDITIONS,
  COLLECTION_DATE_MUST_BE_SELECTED,
  COLLECTION_TIME_MUST_BE_SELECTED,
  DELIVERY_DATE_MUST_BE_SELECTED,
  DELIVERY_SERVICE_MUST_BE_SELECTED,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PHONE,
  ENTER_VALID_POSTCODE,
  INVALID_PICKUP_DATE,
  INVALID_PICKUP_LOCATION_CODE,
  MAX_35_CHARACTERS,
  MAX_50_CHARACTERS,
  NEIGHBOUR_MUST_BE_SELECTED,
  PLEASE_PROVIDE_INSTRUCTIONS,
  PROVIDE_CITY,
  PROVIDE_CONTACT_NAME,
  PROVIDE_COUNTRY,
  PROVIDE_NAME,
  PROVIDE_PHONE_EMAIL,
  PROVIDE_POSTCODE,
  PROVIDE_STREET,
  SAFE_PLACE_MUST_BE_SELECTED,
  SELECT_OTHER_AND,
  SELECT_RETURNED_ADDRESS,
  TICK_CHECKBOX,
} from "../../constants/strings";
import { EMAIL, PHONE_NUMBER, POSTCODE } from "./regex";
import { checkIfPostcodeValid } from "../postcode";

export const notificationDetailsValidator = values => {
  const errors = {};
  let phoneWithoutSpaces = values.mobile?.replace(/\s+/g, "");

  if (phoneWithoutSpaces?.match(/^\+/)) {
    phoneWithoutSpaces = phoneWithoutSpaces.substring(1); // remove +
  }

  if (!values.contactName) {
    errors.contactName = PROVIDE_CONTACT_NAME;
    errors._error = PROVIDE_CONTACT_NAME;
  }

  if (!values.mobile && !values.email) {
    errors.mobile = PROVIDE_PHONE_EMAIL;
    errors.email = PROVIDE_PHONE_EMAIL;
    errors._error = PROVIDE_PHONE_EMAIL;
  }

  if (
    values.mobile &&
    (!PHONE_NUMBER.test(values?.mobile) ||
      phoneWithoutSpaces?.length < 7 ||
      phoneWithoutSpaces?.length > 15)
  ) {
    errors.mobile = ENTER_VALID_PHONE;
    errors._error = ENTER_VALID_PHONE;
  }

  if (values.email && !EMAIL.test(values.email)) {
    errors.email = ENTER_VALID_EMAIL;
    errors._error = ENTER_VALID_EMAIL;
  }

  return errors;
};

export const safePlaceValidator = values => {
  const errors = {};

  if (values.isDatesAvailable && !values.deliveryDate) {
    errors.deliveryDate = DELIVERY_DATE_MUST_BE_SELECTED;
    errors._error = DELIVERY_DATE_MUST_BE_SELECTED;
  }

  if (!values.safePlaceCode) {
    errors.safePlaceCode = SAFE_PLACE_MUST_BE_SELECTED;
    errors._error = SAFE_PLACE_MUST_BE_SELECTED;
  }

  if (values.safePlaceCode === SAFE_PLACE_TYPE.OTH && !values.instructions) {
    errors.instructions = SELECT_OTHER_AND;
    errors._error = SELECT_OTHER_AND;
  }

  if (!values.agreement) {
    errors.agreement = ACCEPT_CONDITIONS;
    errors._error = ACCEPT_CONDITIONS;
  }

  if (values.contactName && values.contactName.trim().length > 35) {
    errors.contactName = MAX_35_CHARACTERS;
    errors._error = MAX_35_CHARACTERS;
  }

  return errors;
};

export const deliverToNeighbourValidator = values => {
  const errors = {};

  if (values.isDatesAvailable && !values.deliveryDate) {
    errors.deliveryDate = DELIVERY_DATE_MUST_BE_SELECTED;
    errors._error = DELIVERY_DATE_MUST_BE_SELECTED;
  }

  if (!values.neighbourAddress) {
    errors.neighbourAddress = NEIGHBOUR_MUST_BE_SELECTED;
    errors._error = NEIGHBOUR_MUST_BE_SELECTED;
  }

  return errors;
};

export const changeDeliveryDateValidator = values => {
  const errors = {};

  if (!values.deliveryDate) {
    errors.deliveryDate = DELIVERY_DATE_MUST_BE_SELECTED;
    errors._error = DELIVERY_DATE_MUST_BE_SELECTED;
  }

  return errors;
};

export const updateContactDetails = values => {
  const errors = notificationDetailsValidator(values);

  return errors;
};

export const provideDeliveryInstructions = values => {
  const errors = {};

  if (!values.instructions) {
    errors.instructions = PLEASE_PROVIDE_INSTRUCTIONS;
    errors._error = PLEASE_PROVIDE_INSTRUCTIONS;
  }

  return errors;
};

export const returnToConsignor = values => {
  const errors = {};

  if (!values.isInternationalParcel) {
    errors.isInternationalParcel = ACCEPT_CONDITIONS;
    errors._error = ACCEPT_CONDITIONS;
  }

  if (!values.deliveryName) {
    errors.deliveryName = PROVIDE_NAME;
    errors._error = PROVIDE_NAME;
  }

  if (!values.deliveryCountryName) {
    errors.deliveryCountryName = PROVIDE_COUNTRY;
    errors._error = PROVIDE_COUNTRY;
  }

  if (
    values.deliveryPostcode &&
    !values.deliveryPostcode.match(POSTCODE) &&
    values.deliveryCountryName === "UK"
  ) {
    errors.deliveryPostcode = ENTER_VALID_POSTCODE;
    errors._error = ENTER_VALID_POSTCODE;
  }

  if (!values.deliveryPostcode) {
    errors.deliveryPostcode = PROVIDE_POSTCODE;
    errors._error = PROVIDE_POSTCODE;
  }

  if (!values.address) {
    errors.address = SELECT_RETURNED_ADDRESS;
    errors._error = SELECT_RETURNED_ADDRESS;
  }

  if (!values.deliveryCity) {
    errors.deliveryCity = PROVIDE_CITY;
    errors._error = PROVIDE_CITY;
  }

  if (values.deliveryCity && values.deliveryCity.trim().length > 50) {
    errors.deliveryCity = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  if (!values.deliveryStreet) {
    errors.deliveryStreet = PROVIDE_STREET;
    errors._error = PROVIDE_STREET;
  }

  if (values.deliveryStreet && values.deliveryStreet.trim().length > 50) {
    errors.deliveryStreet = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  if (values.sendersReference && values.sendersReference.trim().length > 50) {
    errors.sendersReference = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  return errors;
};

export const alternativeAddress = (values, props) => {
  const errors = {};

  if (!values.deliveryDate) {
    errors.deliveryDate = DELIVERY_DATE_MUST_BE_SELECTED;
    errors._error = DELIVERY_DATE_MUST_BE_SELECTED;
  }

  if (!values.name) {
    errors.name = PROVIDE_NAME;
    errors._error = PROVIDE_NAME;
  }

  if (!values.city) {
    errors.city = PROVIDE_CITY;
    errors._error = PROVIDE_CITY;
  }

  if (!values.street) {
    errors.street = PROVIDE_STREET;
    errors._error = PROVIDE_STREET;
  }

  if (!values.agreement) {
    errors.agreement = ACCEPT_CONDITIONS;
    errors._error = ACCEPT_CONDITIONS;
  }

  if (props.isPostcodeRequired && !values.postcode) {
    errors.postcode = PROVIDE_POSTCODE;
    errors._error = PROVIDE_POSTCODE;
  }

  if (props.isPostcodeRequired && values.postcode) {
    const isPostcodeValid = checkIfPostcodeValid({
      postcode: values.postcode,
      countryCode: values.countryCode,
      countries: props.countries,
    });

    if (!isPostcodeValid) {
      errors.postcode = ENTER_VALID_POSTCODE;
      errors._error = ENTER_VALID_POSTCODE;
    }
  }

  if (values.sendersReference && values.sendersReference.trim().length > 50) {
    errors.sendersReference = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  if (values.name && values.name.trim().length > 50) {
    errors.name = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  if (values.street && values.street.trim().length > 50) {
    errors.street = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  if (values.city && values.city.trim().length > 50) {
    errors.city = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  return errors;
};

export const alternativeAddressAndService = (values, props) => {
  const errors = alternativeAddress(values, props);

  if (!values.deliveryService) {
    errors.deliveryService = DELIVERY_SERVICE_MUST_BE_SELECTED;
    errors._error = DELIVERY_SERVICE_MUST_BE_SELECTED;
  }

  return errors;
};

export const callMe = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.notes) {
    errors.notes = SELECT_OTHER_AND;
    errors._error = SELECT_OTHER_AND;
  }

  return errors;
};

export const adjustService = values => {
  const errors = {};

  if (!values.freeOfCharge) {
    errors.freeOfCharge = ACCEPT_CONDITIONS;
    errors._error = ACCEPT_CONDITIONS;
  }

  if (!values.deliveryDate) {
    errors.deliveryDate = DELIVERY_DATE_MUST_BE_SELECTED;
    errors._error = DELIVERY_DATE_MUST_BE_SELECTED;
  }

  if (!values.serviceCode) {
    errors.serviceCode = DELIVERY_SERVICE_MUST_BE_SELECTED;
    errors._error = DELIVERY_SERVICE_MUST_BE_SELECTED;
  }

  if (values.sendersReference && values.sendersReference.trim().length > 50) {
    errors.sendersReference = MAX_50_CHARACTERS;
    errors._error = MAX_50_CHARACTERS;
  }

  return errors;
};

export const collectFromPickupShop = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.pickupLocationCode) {
    errors.pickupLocationCode = INVALID_PICKUP_LOCATION_CODE;
    errors._error = INVALID_PICKUP_LOCATION_CODE;
  }

  if (!values.pickupDate) {
    errors.pickupDate = INVALID_PICKUP_DATE;
    errors._error = INVALID_PICKUP_DATE;
  }

  return errors;
};

export const collectFromDepot = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.diaryDate) {
    errors.diaryDate = COLLECTION_DATE_MUST_BE_SELECTED;
    errors._error = COLLECTION_DATE_MUST_BE_SELECTED;
  }

  if (!values.diaryTime) {
    errors.diaryTime = COLLECTION_TIME_MUST_BE_SELECTED;
    errors._error = COLLECTION_TIME_MUST_BE_SELECTED;
  }

  return errors;
};

export const disposeRequest = values => {
  const errors = {};

  if (!values.agreement) {
    errors.agreement = TICK_CHECKBOX;
    errors._error = TICK_CHECKBOX;
  }

  return errors;
};
